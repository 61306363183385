import styled from "@emotion/styled"
import Img from "gatsby-image"
import { kebabCase } from "lodash"
import React from "react"
import tw from "twin.macro"
import { Link } from "gatsby-plugin-intl"
const CardCentered = (props) => (
  <CardContainer>
    <Link
      to={`/albums/${kebabCase(props.album)}/${kebabCase(props.slug)}/`}
      className="relative flex flex-col-reverse items-center justify-start overflow-hidden shadow-sm card"
    >
      <Meta>
        <Title className="">{props.heading}</Title>
        {props.category && <Subtitle className="">{props.category}</Subtitle>}
      </Meta>
      <Img
        title={props.heading}
        alt={props.heading}
        fluid={props.image}
        className="h-64 image"
      />
    </Link>
  </CardContainer>
)

export default CardCentered

const Meta = styled.div`
  ${tw`absolute inset-0 z-50 flex flex-col items-center justify-center w-full p-1 py-3 text-lg text-left text-gray-800 `}
  background: rgba(0,0,0,.4);
  transition: all 1s;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`

const Title = styled.h1`
  ${tw`top-0 z-10 px-1 text-base font-light text-center text-gray-100 `}
`
const Subtitle = styled.h4`
  ${tw`top-0 z-10 px-3 text-sm font-bold text-center text-gray-300 `}
`

const CardContainer = styled.div`
  ${tw`top-0 z-10 text-base `}

  ${Meta} {
    ${tw`border-b border-transparent `}
  }

  .image {
    ${tw`inset-0 w-full opacity-100 `}
    transition: all 1s;
    transform: scale(1);
  }

  &:hover {
    .image {
      transform: scale(1.03);
    }

    ${Meta} {
      opacity: 1;
      ${tw`border-gray-800`}
    }
  }
`
