import { graphql } from "gatsby"
import React from "react"
import Card from "../components/Card/cardCenter"
import Layout from "../components/layout"
//import { Link } from "gatsby"
import SEO from "../components/seo"
import "twin.macro"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
const WorkArchive = ({ data, pageContext, location }) => {
  const categories = data.contentfulCategorias
  const intl = useIntl()
  return (
    <Layout location={location}>
      <SEO
        lang={intl.locale}
        title={`  ${intl.formatMessage({ id: "see_gallery" })} | ${
          categories.title
        } `}
        image={`${categories.featuredImg.file.url}`}
      />
      <div tw="max-w-2xl px-2 pt-32 m-auto lg:max-w-4xl">
        <div tw="flex flex-col">
          <div
            tw="flex items-baseline"
            className="top-0 z-50 bg-black md:sticky"
          >
            <h1 tw="flex-1 py-2 text-3xl text-left capitalize md:text-3xl ">
              {categories.title}
            </h1>
            <Link
              tw="text-sm tracking-wider text-yellow-700 uppercase duration-500 border-b border-yellow-700 md:text-base md:ml-3"
              to={`/albums`}
            >
              <FormattedMessage id="see" /> <FormattedMessage id="all" />
            </Link>
          </div>

          {categories.workAlbums ? (
            <div tw="grid w-full grid-cols-2 gap-1">
              {categories.workAlbums.map((item, i) => (
                <Card
                  heading={item.title}
                  text={item.title}
                  album={categories.slug}
                  slug={item.slug}
                  image={item.featuredImg.fluid}
                />
              ))}
            </div>
          ) : (
            <div tw="text-center text-gray-500 ">Contenido por cargar</div>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default WorkArchive

export const pageQuery = graphql`
  query($slug: String!, $locale: String) {
    contentfulCategorias(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      id
      title
      slug
      workAlbums {
        title
        id
        slug
        featuredImg {
          fixed(width: 200, height: 200) {
            ...GatsbyContentfulFixed
          }
          file {
            url
          }
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      works {
        id
        title
        slug
        AlbumCategory {
          title
          slug
        }
        featuredImg {
          fixed(width: 200, height: 200) {
            ...GatsbyContentfulFixed
          }
          fluid(maxWidth: 800) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
      featuredImg {
        fixed(width: 600, height: 600) {
          ...GatsbyContentfulFixed
        }
        file {
          url
        }
        fluid(maxWidth: 600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`
